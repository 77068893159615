import React from "react"
import cx from "classnames"
import nl2br from "react-nl2br"
import { isMobile } from "react-device-detect"

import withStyles from "@material-ui/styles/withStyles"
import Layout from "../components/layout"

import angular from "../images/techs/angular.png"
import react from "../images/techs/react.png"
import meteor from "../images/techs/meteor.png"
import nodejs from "../images/techs/nodejs.png"
import cypress from "../images/techs/cypress.jpg"
import aws from "../images/techs/aws.svg"
import docker from "../images/techs/docker.png"
import mongo from "../images/techs/mongo.png"
import electron from "../images/techs/electron.png"
import reactNative from "../images/techs/reactNative.png"
import jisty from "../images/techs/jisty.png"
import technical from "../images/techs/technical.jpg"

import mountain from "../images/mountain.jpg"

const steps = [
  {
    images: [react, meteor, nodejs, jisty],
    techs: ["React", "Node JS", "Meteor JS", "Next.JS", "GraphQL", "Jitsy", "php"],
    title: "Technologies web",
    text: `Nous nous efforçons de trouver le bon compromis entre maturité et efficacité dans le choix de nos stacks de développement. Ayant pour ambition d'adopter une approche agile et efficiente sur vos projets, c'est naturellement que notre expertise se polarise autour de l'écosystème fullstack de JavaScript et de ses frameworks (React, Node, Meteor JS...).`,
  },
  {
    images: [cypress, aws, docker, mongo],
    techs: ["AWS", "Linux", "GitLab", "Docker", "MongoDB", "Cypress"],
    title: "DevOps",
    text: `Afin d'assurer la sérénité de vos projets, nos compétences en DevOps nous permettent de prendre en charge la partie infrastructure. Pour vous permettre d'évoluer rapidement dans un monde fortement concurrentiel, nous intégrons en continu un code testé et validé (CI/CD).`,
  },
  {
    images: [reactNative, electron],
    techs: ["React Native", "Electron"],
    title: "Mobile et desktop",
    text:
      "La frontière entre les environnements de développement a tendance à s'effacer de plus en plus. Nous avons ainsi développé une expertise dans le développement multi-plateformes, nous permettant de proposer du code hybride ou natif, notamment grace à React Native",
  },
  {
    images: [technical],
    title: "Accompagnement technique",
    text: `Nous cherchons à établir des rapports durables avec tous nos partenaires. C'est pourquoi nous vous proposons un accompagnement technique dimensionné sur mesure pour vous et votre projet (formation, maintenance, assistance, ...) `,
  },
]

const styles = {
  relativeWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",

    "@media (max-width:960px)": {
      overflowY: "scroll",
    },
  },

  contentWrapper: {
    "@media (min-width:960px)": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    display: "flex",
    justifyContent: "space-between",
  },

  imageIllustration: {
    "@media (max-width:960px)": {
      display: "none !important",
    },
  },

  half: {
    display: "flex",
    height: "100%",
    width: "50%",

    "@media (max-width:960px)": {
      width: "auto",
      marginRight: "1rem",
      marginLeft: "1rem",
    },
  },

  left: {
    flexDirection: "column",
    justifyContent: "space-around",
    height: "90%",
    marginTop: "5%",
  },

  right: {
    "& .image": {
      width: "100%",
      height: "100%",
      position: "relative",
      clipPath: "polygon(60% 0%, 0 100%, 100% 100%, 100% 0)",
      backgroundPosition: "top",

      "&:hover": {},

      "&:before": {
        content: " ",
        background: "black",
        position: "absolute",
        top: 0,
        left: "-10px",
        right: "-10px",
        bottom: 0,
        zIndex: -1,
        transform: "skewX(-15deg)",
      },
    },
  },
  techs: {
    color: "#929a98",
  },
}

const Flag = withStyles({
  flag: {
    backgroundColor: "white",
    margin: ".5rem 0",
    transition: "all .7s ease",
    "@media (min-width:960px)": {
      display: "flex",
      height: `${90 / steps.length}%`,
      clipPath: `polygon(0% 0%, 0% 100%, calc(100% - 6.2vw) 100%, 100% 0)`,
    },

    "& .step": {
      display: "flex",
      flexDirection: "column",
      padding: ".5rem 5rem .5rem 0",
      alignSelf: "flex-start",
      width: "calc(100% - 10rem)",
      height: "100%",
      "@media (max-width:960px)": {
        width: "auto",
        padding: ".5rem .5rem .5rem 0",
        margin: "1rem",
        textAlign: "justify",
      },

      "& .step-title": {
        margin: ".5rem 0rem",

        "@media (max-width:960px)": {
          fontSize: "1.2rem",
        },
      },

      "& .step-text": {
        display: "flex",
        alignItems: "center",
        height: "100%",
        "@media (max-width:960px)": {
          fontSize: "1rem",
        },
      },
    },

    "& .imagesBlock": {
      marginRight: "40px",
      marginLeft: "20px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "200px",
      flexWrap: "wrap",

      "@media (max-width:960px)": {
        marginTop: "1rem",
      },

      "& img": {
        width: "110px",
      },
    },
  },
})(
  class UnstyledFlag extends React.Component {
    render() {
      const { classes, order, step } = this.props

      const width = isMobile ? "auto" : `${150 - (order * 54) / steps.length}%`

      return (
        <div
          className={`paperize-4 ${classes.flag}`}
          style={{
            width,
          }}
        >
          <div className="imagesBlock">
            {step.images.map(image => (
              <img
                alt={step.title}
                key={image}
                src={image}
                style={{ maxWidth: "80px" }}
              />
            ))}
          </div>
          <div className="step">
            <h3 className="step-title">{step.title}</h3>
            {step.techs && (
              <h4 style={{ color: "#929a98", margin: "0" }}>
                {step.techs.map(techno => (
                  <i key={techno}>{techno}, </i>
                ))}
                ...
              </h4>
            )}
            <div className="step-text">
              <div>{nl2br(step.text)}</div>
            </div>
          </div>
        </div>
      )
    }
  }
)

class Skills extends React.Component {
  _imgOffset = {
    x: -200,
    y: -60,
  }

  state = {
    showFlags: true,
    imgOffset: { ...this._imgOffset },
    hover: false,
  }

  componentDidMount() {
    this.movStrength = 35
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    this.height = this.movStrength / this.windowHeight
    this.width = this.movStrength / this.windowWidth
  }

  handleMouseLeave = () => this.setState({ hover: false })

  render() {
    const { showFlags, imgOffset, hover } = this.state
    const { classes } = this.props
    const opacity = hover ? ".15" : "0.35"

    return (
      <Layout>
        <div className={classes.relativeWrapper}>
          <div className={`${classes.contentWrapper}`}>
            <div className={cx(classes.half, classes.left)}>
              {steps.map((step, idx) => (
                <Flag order={idx} key={idx} showFlags={showFlags} step={step} />
              ))}
            </div>
            <div
              className={cx(
                classes.half,
                classes.right,
                classes.imageIllustration
              )}
            >
              <div
                className="image"
                style={{
                  width: "1300px",
                  background: `linear-gradient( rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, ${opacity}) ), url(${mountain}) ${imgOffset.x}px ${imgOffset.y}px no-repeat`,
                  backgroundSize: "1900px 1300px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(Skills)
